import { type ChangeEvent, useRef, useState } from "react";
import {
  Box,
  Text,
  UserBlock,
  LabelWrapper,
  TextField,
} from "@cruk/cruk-react-components";

import Editable from "@fwa/src/components/Editable";
import {
  DISPLAY_NAME_MAX_LENGTH,
  validateDisplayName,
} from "@fwa/src/validation/page";
import { DisabledTextBorder } from "@fwa/src/components/EditableDisplayNameForm/styles";

import { type PageType } from "@fwa/src/types";
import { calcLength } from "@fwa/src/utils/formUtils";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

type Props = {
  avatarUrl: string;
  canEdit: boolean;
  editButtonColor?: string;
  fieldName: string;
  fullWidth?: boolean;
  handleEditData: (
    data: Record<string, string | object>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
  page: PageType;
  userName: string;
};

export const EditableDisplayNameForm = ({
  avatarUrl,
  canEdit,
  editButtonColor,
  fieldName,
  fullWidth = true,
  handleEditData,
  page,
  userName,
}: Props) => {
  const initialValue = page?.owner?.displayName || "";
  const textRef = useRef<HTMLInputElement>(null);

  const { trackError } = useTrackingContext();

  const [validationMessage, setValidationMessage] = useState<string>("");
  const [charCount, setCharCount] = useState(calcLength(initialValue || ""));
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (): Promise<PageType | void> => {
    if (!textRef.current) return;
    const validationMessageString = validateDisplayName(
      textRef?.current?.value,
    );
    setValidationMessage(validationMessageString || "");
    if (validationMessageString) {
      return;
    } else {
      setIsLoading(true);
      return handleEditData(
        {
          owner: {
            displayName: textRef?.current?.value,
          },
        },
        false,
      )
        .then((pageData) => {
          setIsLoading(false);
          return pageData;
        })
        .catch((error) => {
          setValidationMessage("Unable to submit");
          setIsLoading(false);
          trackError(error as Error, { component: "EditableDisplayNameForm" });
          return;
        });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    setCharCount(calcLength(input.value));
  };

  return canEdit ? (
    <Editable
      fullWidth={fullWidth}
      editNode={
        <Box>
          <TextField
            name={`${page.fundraiser.forename} ${page.fundraiser.surname}`}
            ref={textRef}
            label="Display name"
            defaultValue={initialValue}
            onChange={handleChange}
            errorMessage={validationMessage}
            hintText="(Optional) Use if you want to display something other than your account name"
            maxLength={DISPLAY_NAME_MAX_LENGTH}
          />

          <Text textAlign="right" marginTop="xxs">{`${
            DISPLAY_NAME_MAX_LENGTH - charCount
          } characters remaining`}</Text>

          <LabelWrapper label="Account name">
            <DisabledTextBorder>
              <Text>{`${page.fundraiser.forename} ${page.fundraiser.surname}`}</Text>
            </DisabledTextBorder>
          </LabelWrapper>
        </Box>
      }
      viewNode={
        <UserBlock
          name={page.owner?.displayName ? page.owner.displayName : userName}
          avatarUrl={avatarUrl}
        />
      }
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      editButtonColor={editButtonColor}
      editButtonPosition="center"
      tooltip="Edit page user display name"
      fieldName={fieldName}
    />
  ) : (
    <UserBlock
      name={page.owner?.displayName ? page.owner.displayName : userName}
      avatarUrl={avatarUrl}
    />
  );
};

export default EditableDisplayNameForm;
