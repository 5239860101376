import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useRef,
  useState,
} from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Box, Text, IconFa } from "@cruk/cruk-react-components";
import {
  CollapseButton,
  CollapseContent,
  Flipper,
} from "@fwa/src/components/CollapseCentered/styles";
import { ColumnAlignCenter } from "@fwa/src/components/styles";

type Props = {
  title: string;
  ariaLabel: string;
  children: ReactNode;
  widenContentBy?: string;
  allCollapseState: boolean[];
  setAllCollapseState: Dispatch<SetStateAction<boolean[]>>;
  collapseIndex: number;
  customHeader: ReactNode;
};

export const CollapseCentered = ({
  title,
  children,
  ariaLabel,
  widenContentBy,
  allCollapseState,
  setAllCollapseState,
  collapseIndex,
  customHeader,
}: Props) => {
  const [contentHeight, setContentHeight] = useState("initial");
  const content = useRef<HTMLDivElement>(null);

  const toggleCollapse = () => {
    const { current } = content;
    if (current !== null) {
      setContentHeight(`${current.scrollHeight}px`);
    }

    const newCollapseState = allCollapseState.map((collapseState, mapIndex) => {
      if (mapIndex === collapseIndex) return !allCollapseState[collapseIndex];
      return false;
    });
    setAllCollapseState(newCollapseState);
  };

  return (
    <>
      <Box margin="none" padding="none" marginVertical="xxs">
        <CollapseButton
          aria-controls="collapsed-content"
          aria-expanded={allCollapseState[collapseIndex]}
          aria-label={ariaLabel}
          aria-description={title}
          appearance="tertiary"
          onClick={() => toggleCollapse()}
          data-component="totaliser-milestone-collapse"
        >
          <ColumnAlignCenter>
            <Box marginBottom="s">{customHeader || null}</Box>
            <Text
              textColor="primary"
              textAlign="center"
              paddingBottom="none"
              marginBottom="none"
            >
              {title}
            </Text>
            <Flipper $open={allCollapseState[collapseIndex]}>
              <IconFa faIcon={faChevronDown} />
            </Flipper>
          </ColumnAlignCenter>
        </CollapseButton>
      </Box>

      <CollapseContent
        id="collapsed-content"
        ref={content}
        aria-hidden={!allCollapseState[collapseIndex]}
        $openStatus={allCollapseState[collapseIndex]}
        $contentHeight={contentHeight}
        $widenContentBy={widenContentBy || ""}
      >
        {children}
      </CollapseContent>
    </>
  );
};

export default CollapseCentered;
