import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { Box, Heading } from "@cruk/cruk-react-components";
import { useInView } from "react-intersection-observer";

import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { ResponsiveReactPlayer } from "@fwa/src/components/FundraisingPageVideo/styles";
import { type FundraisingPageType } from "@fwa/src/types";

const EditableVideoUrlForm = dynamic(
  () => import("@fwa/src/components/EditableVideoUrlForm"),
);

type Props = {
  canEdit: boolean;
  page: FundraisingPageType;
  updateAndMutatePage: (
    data: Partial<FundraisingPageType>,
    refresh?: boolean,
  ) => Promise<void | FundraisingPageType>;
};

export const FundraisingPageVideo = ({
  canEdit,
  page,
  updateAndMutatePage,
}: Props) => {
  const { setOptimizelyUserAttributes } = useOptimizelyContext();

  const [hasPlayed, setHasPlayed] = useState(false);

  const { ref: innerRef, inView } = useInView({
    triggerOnce: true,
  });

  const handlePlay = () => {
    setOptimizelyUserAttributes({ video_played: 1 });
    setHasPlayed(true);
  };

  useEffect(() => {
    if (inView) {
      const actualHasPlayed = hasPlayed ? 1 : 0;
      setOptimizelyUserAttributes({ video_played: actualHasPlayed });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {canEdit || page.videoUrl ? (
        <Box
          id="videoUrl"
          marginBottom="xxs"
          paddingTop="l"
          data-component="video"
        >
          <Heading h2 textSize="xl" marginBottom="xs">
            My Video
          </Heading>
          {canEdit ? (
            <EditableVideoUrlForm
              handleEditData={updateAndMutatePage}
              page={page}
            />
          ) : page?.videoUrl ? (
            <div ref={innerRef}>
              <ResponsiveReactPlayer
                url={page.videoUrl || ""}
                height="auto"
                width="100%"
                onPlay={() => handlePlay()}
                config={{
                  youtube: {
                    playerVars: {
                      origin: "https://www.youtube.com",
                      // widget_referrer: FWA_BASE_URL,  can track our views in YT studio
                    },
                  },
                }}
              />
            </div>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

export default FundraisingPageVideo;
