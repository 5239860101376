import dynamic from "next/dynamic";

import { type StravaType, type FundraisingPageType } from "@fwa/src/types";

const StravaTracker = dynamic(
  () => import("@fwa/src/components/StravaTracker"),
);

const StravaTrackerForm = dynamic(
  () => import("@fwa/src/components/StravaTrackerForm"),
);

export const StravaSection = ({
  strava,
  mutatePage,
  canEdit,
}: {
  canEdit: boolean;
  strava: StravaType;
  mutatePage: (
    data: Partial<FundraisingPageType>,
  ) => Promise<undefined | void | FundraisingPageType>;
}) => {
  if (!canEdit) {
    return <StravaTracker canEdit={canEdit} strava={strava} />;
  } else {
    return <StravaTrackerForm strava={strava} mutatePage={mutatePage} />;
  }
};

export default StravaSection;
