import dynamic from "next/dynamic";
import styled from "styled-components";
import { formatInTimeZone } from "date-fns-tz";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { Box, IconFa, Text, type ThemeType } from "@cruk/cruk-react-components";

import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { fwsUrlPageParentMemberships } from "@fwa/src/services/fundraisingPageService";

import { PageMemberOf } from "@fwa/src/components/PageMemberOf";

import {
  ActivityIconWrapper,
  ActivityItem,
  ActivityItemWrapper,
} from "@fwa/src/components/styles";

import {
  type FundraisingPageType,
  type FwsResponseData,
  type TeamMemberType,
} from "@fwa/src/types";

const FundraisingPageActivityDateForm = dynamic(
  () => import("@fwa/src/components/FundraisingPageActivityDateForm"),
);
const FundraisingPageActivityNameForm = dynamic(
  () => import("@fwa/src/components/FundraisingPageActivityNameForm"),
);

const ActivityItems = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
    justify-content: center;
  }
`;

const TextResponsive = styled(Text)<{ theme: ThemeType }>`
  text-align: left;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: center;
  }
`;

type Props = {
  page: FundraisingPageType;
  canEdit: boolean;
  updateAndMutatePage: (
    data: Partial<FundraisingPageType>,
    refresh?: boolean,
  ) => Promise<void | FundraisingPageType>;
};

export const FundraisingPageActivityBlock = ({
  page,
  canEdit,
  updateAndMutatePage,
}: Props) => {
  const url = page
    ? `${fwsUrlPageParentMemberships({
        pageId: page.uniqueId,
        currentPage: 1,
        itemsPerPage: 3,
      })}`
    : null;
  const { data } = useSwrGet<FwsResponseData<TeamMemberType>>(url);
  const memberships = data?.results || [];

  // Example activityDate:  "2020-12-20T11:51:28+00:00" looks like toISOString()
  const activityDateTimeString =
    !!page && page.activityDateTime
      ? formatInTimeZone(
          new Date(page.activityDateTime),
          "Europe/London",
          "EEE d LLL y",
        )
      : "";

  const activityDateTimeEndString =
    page?.activityDateTimeEnd &&
    page?.activityDateTimeEnd !== page?.activityDateTime
      ? ` - ${formatInTimeZone(
          new Date(page.activityDateTimeEnd),
          "Europe/London",
          "EEE d LLL y",
        )}`
      : "";

  const fullDateRangeString = `${activityDateTimeString}${activityDateTimeEndString}`;
  const activityString = page?.activityName || page?.event?.eventName || "";

  if (canEdit) {
    return (
      <Box
        backgroundColor="backgroundLight"
        paddingTop="none"
        data-component="page-activity-block"
      >
        <ActivityItems>
          <ActivityItemWrapper data-component="page-activity-name-form">
            <FundraisingPageActivityNameForm
              activityName={activityString}
              handleEditData={updateAndMutatePage}
            />
          </ActivityItemWrapper>
          <ActivityItemWrapper data-component="page-activity-date-form">
            <FundraisingPageActivityDateForm
              startDate={page.activityDateTime || null}
              endDate={page.activityDateTimeEnd || null}
              handleEditData={updateAndMutatePage}
            />
          </ActivityItemWrapper>
          {data ? (
            <PageMemberOf page={page} fallbackData={data} canEdit={canEdit} />
          ) : null}
        </ActivityItems>
      </Box>
    );
  }

  if (activityString.length || page.activityDateTime || !!memberships.length) {
    return (
      <Box
        backgroundColor="backgroundLight"
        paddingTop="none"
        data-component="page-activity-block"
      >
        <ActivityItems>
          {activityString.length ? (
            <ActivityItemWrapper data-component="page-activity-name">
              <ActivityItem>
                <ActivityIconWrapper>
                  <IconFa faIcon={faBullhorn} size="100%" />
                </ActivityIconWrapper>
                <TextResponsive>{activityString}</TextResponsive>
              </ActivityItem>
            </ActivityItemWrapper>
          ) : null}
          {page.activityDateTime ? (
            <ActivityItemWrapper data-component="page-activity-date">
              <ActivityItem>
                <ActivityIconWrapper>
                  <IconFa faIcon={faCalendarDays} size="100%" />
                </ActivityIconWrapper>
                <TextResponsive>{fullDateRangeString}</TextResponsive>
              </ActivityItem>
            </ActivityItemWrapper>
          ) : null}

          {data ? (
            <PageMemberOf page={page} fallbackData={data} canEdit={canEdit} />
          ) : null}
        </ActivityItems>
      </Box>
    );
  }

  return null;
};

export default FundraisingPageActivityBlock;
